import { ActionContext } from "app/components/action/ActionContext";
import { UnlockConnectorRequest } from "app/components/action/model/Actions";
import { ActionType } from "app/components/action/model/ActionType";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import useErrorHandler from "app/hooks/useErrorHandler";
import useRouter from "app/hooks/useRouter";
import OcppApiService from "app/services/OcppApiService";
import { Form, Formik, useFormikContext } from "formik";
import { Stack } from "lib/components";
import Button from "lib/components/Button/Button";
import { DialogContent, DialogFooter, DialogHeader } from "lib/components/Dialog";
import TextField from "lib/components/TextField/TextField";
import { useContext } from "react";
import * as yup from "yup";

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const { values, handleChange, isValid, errors } = useFormikContext<UnlockConnectorRequest>();

    return (
        <Form>
            <DialogHeader>Unlock Connector</DialogHeader>
            <DialogContent>
                <Stack direction="column" spacing="3rem">
                    <TextField
                        label="Connector ID"
                        fullWidth
                        id="connectorId"
                        name="connectorId"
                        value={values.connectorId}
                        onChange={handleChange}
                        error={Boolean(errors.connectorId)}
                        helperText={errors.connectorId ? errors.connectorId : ""}
                    />
                </Stack>
            </DialogContent>
            <DialogFooter>
                <Button onClick={actionContext.onModalClose} variant="secondary">
                    Cancel
                </Button>
                <Button type="submit" variant="primary" disabled={!isValid}>
                    Submit
                </Button>
            </DialogFooter>
        </Form>
    );
};

const UnlockConnectorForm = () => {
    const { params } = useRouter();
    const actionContext = useContext(ActionContext);
    const { onError } = useErrorHandler();

    const handleSubmit = async (request: UnlockConnectorRequest) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.UnlockConnector, params.identity, request);
            await OcppApiService.unlockConnector(request);
            actionContext.onModalClose();
        } catch (error) {
            onError(error, "Command Failed: ");
        } finally {
        }
    };

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                connectorId: 1,
            }}
            validationSchema={yup.object({
                connectorId: yup.number().required().min(1, "Connector ID must be greater than 0"),
            })}
            onSubmit={handleSubmit}
        >
            <InnerForm />
        </Formik>
    );
};

export default UnlockConnectorForm;
