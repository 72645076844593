import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ActionContext } from "app/components/action/ActionContext";
import { SetChargingProfile } from "app/components/action/model/Actions";
import { ActionType } from "app/components/action/model/ActionType";
import { ChargingProfilePurposeType } from "app/components/action/model/ChargingProfilePurposeType";
import { ChargingRateUnitType } from "app/components/action/model/ChargingRateUnitType";
import { NumberPhases } from "app/components/action/model/NumberPhases";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import useErrorHandler from "app/hooks/useErrorHandler";
import useRouter from "app/hooks/useRouter";
import OcppApiService from "app/services/OcppApiService";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Form, Formik, useFormikContext } from "formik";
import { Stack } from "lib/components";
import Button from "lib/components/Button/Button";
import { DialogContent, DialogFooter, DialogHeader } from "lib/components/Dialog";
import Dropdown from "lib/components/Dropdown/Dropdown";
import TextField from "lib/components/TextField/TextField";
import { ChangeEvent, useContext } from "react";
import * as yup from "yup";
import { ChargePoint } from "app/components/chargepoint/model/ChargePoint";

const chargingProfilePurposeTypes = [
    {
        id: "ChargePointMaxProfile",
        value: "ChargePointMaxProfile",
    },
    {
        id: "TxDefaultProfile",
        value: "TxDefaultProfile",
    },
    {
        id: "TxProfile",
        value: "TxProfile",
    },
];

const rateUnits = [
    {
        id: "A",
        value: "A",
    },
    {
        id: "W",
        value: "W",
    },
    {
        id: "VAR",
        value: "VAR (alpitronic only)",
    },
];

const numberPhases = [
    {
        id: "1",
        value: "1",
    },
    {
        id: "3",
        value: "3",
    },
];

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const { values, handleChange, setFieldValue, isValid, errors } = useFormikContext<SetChargingProfile>();
    dayjs.extend(utc);

    const handleChargingProfilePurposeTypeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setFieldValue("chargingProfilePurposeType", value);
    };

    const handleRateUnitChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setFieldValue("rateUnit", value);
    };

    const handleNumberPhaseChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setFieldValue("numberPhases", value);
    };

    return (
        <Form>
            <DialogHeader>Set charging profile</DialogHeader>
            <DialogContent>
                <Stack direction="column" spacing="3rem">
                    <Dropdown
                        label="Message"
                        onChange={handleChargingProfilePurposeTypeChange}
                        options={chargingProfilePurposeTypes}
                        value={values.chargingProfilePurposeType}
                    />
                    <TextField
                        label="Connector ID"
                        fullWidth
                        id="connectorId"
                        name="connectorId"
                        value={values.connectorId}
                        onChange={handleChange}
                        error={Boolean(errors.connectorId)}
                        helperText={errors.connectorId ? errors.connectorId : ""}
                    />
                    <Dropdown label="Rate unit" onChange={handleRateUnitChange} options={rateUnits} value={values.rateUnit} />
                    <Dropdown
                        label="Number of phases"
                        onChange={handleNumberPhaseChange}
                        options={numberPhases}
                        value={values.numberPhases}
                    />
                    <TextField
                        label="Limit"
                        fullWidth
                        id="limit"
                        name="limit"
                        value={values.limit}
                        onChange={handleChange}
                        error={Boolean(errors.limit)}
                        helperText={errors.limit ? errors.limit : ""}
                    />
                    <TextField
                        label="Stack"
                        fullWidth
                        id="stackLevel"
                        name="stackLevel"
                        value={values.stackLevel}
                        onChange={handleChange}
                        error={Boolean(errors.stackLevel)}
                        helperText={errors.stackLevel ? errors.stackLevel : ""}
                    />
                    <TextField
                        label="Transaction ID"
                        fullWidth
                        id="transactionId"
                        name="transactionId"
                        value={values.transactionId}
                        onChange={handleChange}
                        error={Boolean(errors.transactionId)}
                        helperText={errors.transactionId ? errors.transactionId : ""}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.utc}>
                        <DateTimeField
                            label="Start schedule (UTC)"
                            value={dayjs(values.startSchedule)}
                            onChange={(newValue) => {
                                setFieldValue("startSchedule", newValue?.toISOString());
                            }}
                            format="DD/MM/YYYY HH:mm"
                        />
                    </LocalizationProvider>
                </Stack>
            </DialogContent>
            <DialogFooter>
                <Button onClick={actionContext.onModalClose} variant="secondary">
                    Cancel
                </Button>
                <Button type="submit" variant="primary" disabled={!isValid}>
                    Submit
                </Button>
            </DialogFooter>
        </Form>
    );
};

const SetChargingProfileForm = (props: { chargePoint: ChargePoint }) => {
    const { params } = useRouter();
    const actionContext = useContext(ActionContext);
    const { onError } = useErrorHandler();

    const transactionId = props.chargePoint.connectors[0].transactions[0]?.id ?? undefined;

    const handleSubmit = async (request: SetChargingProfile) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.SetChargingProfile, params.identity, request);
            await OcppApiService.setChargingProfile(request);
            actionContext.onModalClose();
        } catch (error) {
            onError(error, "Command Failed: ");
        } finally {
        }
    };
    return (
        <Formik
            initialValues={{
                identity: params.identity,
                chargingProfilePurposeType: ChargingProfilePurposeType.TxProfile,
                limit: 0,
                rateUnit: ChargingRateUnitType.A,
                numberPhases: NumberPhases.ThreePhase,
                stackLevel: 0,
                connectorId: 1,
                transactionId: transactionId,
                startSchedule: dayjs().toISOString(),
            }}
            validationSchema={yup.object({
                limit: yup.string().required("Connector ID is a required field"),
                stackLevel: yup.string().required("Stack level is a required field"),
            })}
            onSubmit={handleSubmit}
        >
            <InnerForm />
        </Formik>
    );
};

export default SetChargingProfileForm;
