import { useEffect, useState } from "react";
import React from "react";
import { ChargePoint } from "app/components/chargepoint/model/ChargePoint";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import OcppApiService from "app/services/OcppApiService";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import SetConfigurationModal from "app/components/chargepoint/SetConfigurationModal";
import Alert from "@mui/material/Alert";

interface OCPPConfigurationProps {
    chargePoint: ChargePoint;
}

const OCPPConfiguration: React.FC<OCPPConfigurationProps> = (props) => {
    const [rows, setRows] = useState<any[]>([]);
    const [showSetConfiguration, setShowSetConfiguration] = useState(false);
    const [configurationKey, setConfigurationKey] = useState("");
    const [configurationValue, setConfigurationValue] = useState("");
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchConfiguration = async () => {
            try {
                const request = { identity: props.chargePoint.identity, keys: [] };
                if (props.chargePoint.identity) {
                    const response = await OcppApiService.getConfiguration(request);
                    if (response.data) {
                        setRows(response.data.configurationKey);
                        setError(null);
                    }
                }
            } catch (err) {
                const error = err as { response?: { status: number } };
                console.error("Failed to fetch configuration:", error);
                if (error.response && error.response.status === 400) {
                    setError("Charge point is not connected. Please check the connection and try again.");
                } else {
                    setError("Failed to fetch configuration. Please try again later.");
                }
            }
        };

        fetchConfiguration();
    }, [props.chargePoint.identity, showSetConfiguration]);

    const columns: GridColDef[] = [
        {
            field: "key",
            flex: 1,
            headerName: "Configuration Key",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "value",
            flex: 1,
            headerName: "Configuration Value",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "edit",
            flex: 0.25,
            headerName: "Edit",
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <IconButton
                        size="small"
                        onClick={() => {
                            setConfigurationKey(params.row.key);
                            setConfigurationValue(params.row.value ?? "");
                            setShowSetConfiguration(true);
                        }}
                    >
                        <Edit fontSize="small" />
                    </IconButton>
                );
            },
        },
    ];

    return (
        <>
            {error && <Alert severity="error">{error}</Alert>}
            {rows && rows.length === 0 ? (
                <div>No configuration available</div>
            ) : (
                <>
                    <DataGrid disableRowSelectionOnClick getRowId={(row) => row.key} rows={rows} columns={columns} />
                    <SetConfigurationModal
                        chargePoint={props.chargePoint}
                        showModal={showSetConfiguration}
                        configurationKey={configurationKey}
                        configurationValue={configurationValue}
                        setShowModal={(showModal) => {
                            if (!showModal) {
                                setConfigurationKey("");
                                setConfigurationValue("");
                            }
                            setShowSetConfiguration(showModal);
                        }}
                    />
                </>
            )}
        </>
    );
};

export default OCPPConfiguration;
