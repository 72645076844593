import { ChargePoint } from "app/components/chargepoint/model/ChargePoint";
import { useEffect, useState } from "react";
import { Button, Dialog, TextField } from "lib/components";
import { DialogActions, DialogTitle } from "@mui/material";
import Stack from "@mui/material/Stack";
import { DialogContent } from "lib/components/Dialog";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import OcppApiService from "app/services/OcppApiService";

interface SetConfigurationModalProps {
    chargePoint: ChargePoint;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    configurationKey?: string;
    configurationValue?: string;
}

const validationSchema = Yup.object().shape({
    key: Yup.string().required("Key is required"),
    value: Yup.string().required("Value is required"),
});

function SetConfigurationModal(props: SetConfigurationModalProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const hideModal = () => {
        if (!loading) {
            props.setShowModal(false);
        }
    };

    return (
        <Dialog isOpen={props.showModal} fullWidth={true} onClose={hideModal}>
            <DialogTitle>Charge Point Configuration</DialogTitle>
            <Formik
                initialValues={{
                    key: props.configurationKey || "",
                    value: props.configurationValue || "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    setLoading(true);
                    const request = {
                        identity: props.chargePoint.identity,
                        keys: [{ key: values.key, value: values.value }],
                    };
                    try {
                        await OcppApiService.changeConfiguration(request);
                    } catch (error) {
                        console.error("Failed to change configuration:", error);
                    } finally {
                        setSubmitting(false);
                        setLoading(false);
                        props.setShowModal(false);
                    }
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <DialogContent>
                            <Stack spacing={2}>
                                <Field name="key">
                                    {({ field }: { field: any }) => (
                                        <TextField
                                            {...field}
                                            inputProps={{ readOnly: true }}
                                            label="Key"
                                            error={touched.key && Boolean(errors.key)}
                                            helperText={touched.key && errors.key}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                                <Field name="value">
                                    {({ field }: { field: any }) => (
                                        <TextField
                                            {...field}
                                            label="Value"
                                            error={touched.value && Boolean(errors.value)}
                                            helperText={touched.value && errors.value}
                                            fullWidth
                                        />
                                    )}
                                </Field>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={hideModal} disabled={loading}>
                                Cancel
                            </Button>
                            <Button type="submit" disabled={loading}>
                                Save
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default SetConfigurationModal;
