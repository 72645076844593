export const options = {
    coral: "coral",
    sand: "sand",
    night: "night",
    ocean: "ocean",
    yellow: "yellow",
    red: "red",
    green: "green",
    plum: "plum",
    raspberry: "raspberry",
};

export const white = "#FFFFFF";

export const black = "#000000";

export const grey = {
    "50": "#F9FAFB",
    "100": "#E3E6E9",
    "200": "#B1B9BE",
    "300": "#7A8389",
    "400": "#46545B",
    "500": "#192832",
};

export const coral = {
    "20": "#FFE2DF",
    "40": "#FFCAC2",
    "60": "#FFA8A0",
    "100": "#FF5252",
    "150": "#E9265E",
};

export const coralGradient = `linear-gradient(92.23deg, ${coral[100]} 0.51%, #FF3162 98.89%)`;
export const coralGradientFallbackDark = `linear-gradient(92.23deg, ${coral[150]} 0.51%, ${coral[150]} 0%)`;
export const coralGradientFallbackDimmed = `linear-gradient(92.23deg, ${coral[40]} 100%, ${coral[40]} 0%)`;

export const sand = {
    "10": "#FDFCFC",
    "20": "#F9F7F6",
    "40": "#F4F2F1",
    "50": "#EDEAE9",
    "60": "#DAD4D3",
    "80": "#C4BCBB",
    "100": "#A99F9F",
};

export const night = {
    "5": "#FDFDFF",
    "10": "#FBFBFF",
    "20": "#F3F4FA",
    "30": "#EFF1F7",
    "40": "#E2E6F0",
    "50": "#C2C7DB",
    "60": "#9096AF",
    "80": "#616986",
    "90": "#4B506B",
    "100": "#343457",
};

export const ocean = {
    "5": "#F2F4FF",
    "10": "#E5E9FF",
    "20": "#CCD4FF",
    "80": "#405DFF",
    "100": "#0027FF",
};

export const plum = {
    "10": "#F6E9F9",
    "20": "#ECD3F2",
    "80": "#B85BCF",
    "100": "#A024BF",
};

export const raspberry = {
    "10": "#FFF3F9",
    "20": "#FFE6F3",
    "80": "#FF9CCF",
    "100": "#FF7ABF",
};

export const yellow = {
    "8": "#FFF7EB",
    "15": "#FFF0D9",
    "30": "#FFE1B5",
    "100": "#FF9A03",
};

export const red = {
    "8": "#F5DCDF",
    "15": "#F5DCDF",
    "30": "#EBB9BF",
    "100": "#BA1628",
};

export const green = {
    "8": "#EBF8F6",
    "15": "#D9F2EE",
    "30": "#B2E6DD",
    "100": "#02A78B",
};

export const primary = {
    main: coral[100],
    dark: coral[150],
    light: coral[60],
    contrastText: "#FFFFFF",
};

export const secondary = {
    main: sand[80],
    dark: sand[100],
    light: sand[60],
    contrastText: "#FFFFFF",
};

export const info = {
    main: night[40],
    dark: night[60],
    light: night[20],
    contrastText: night[100],
};

export const error = {
    main: red[15],
    dark: red[30],
    light: red[8],
    contrastText: red[100],
};

export const warning = {
    main: yellow[15],
    dark: yellow[30],
    light: yellow[8],
    contrastText: yellow[100],
};

export const success = {
    main: green[15],
    dark: green[30],
    light: green[8],
    contrastText: green[100],
};
