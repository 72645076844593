import Box from "@mui/material/Box";
import MuiButton, { ButtonPropsColorOverrides, ButtonPropsVariantOverrides } from "@mui/material/Button";
import { OverridableStringUnion } from "@mui/types";
import React, { forwardRef, Ref } from "react";

import Icon from "../Icon";

import { ButtonProps } from "./types";

const getVariant = (
    variant: ButtonProps["variant"]
): OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides> => {
    switch (variant) {
        case "primary":
            return "contained";
        case "secondary":
            return "outlined";
        case "text":
            return "text";
        default:
            return "contained";
    }
};

const getColor = (
    variant: ButtonProps["variant"]
): OverridableStringUnion<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides> => {
    switch (variant) {
        case "primary":
            return "primary";
        case "secondary":
            return "secondary";
        case "text":
            return "secondary";
        default:
            return "primary";
    }
};

const Button = (
    { children, size = "medium", variant = "primary", disabled = false, icon, href, ...props }: ButtonProps,
    ref: Ref<HTMLButtonElement>
): React.JSX.Element => {
    const buttonProps = {
        ref,
        size,
        variant: getVariant(variant),
        color: getColor(variant),
        disabled,
        href,
        ...(href && {
            target: "_blank",
            rel: "noopener",
        }),
        ...(icon && children && { startIcon: <Icon name={icon} /> }),
        ...props,
        ...(icon &&
            !children && {
                sx: {
                    minWidth: "0",
                    "&.MuiButton-sizeSmall": {
                        padding: "0.8rem",
                    },
                    "&.MuiButton-sizeMedium": {
                        padding: "1.2rem",
                    },
                    "&.MuiButton-sizeLarge": {
                        padding: "1.4rem",
                    },
                },
            }),
    };

    return (
        <MuiButton {...buttonProps}>
            {icon && !children ? (
                <Box sx={{ height: "1.6rem" }}>
                    <Icon name={icon} size="inherit" />
                </Box>
            ) : (
                children
            )}
        </MuiButton>
    );
};

export default forwardRef(Button);
