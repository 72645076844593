import { PlayArrow, Replay } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Stack } from "lib/components";
import Card from "lib/components/Card";
import React, { useEffect, useRef } from "react";
import AutoTestService from "../components/auto/api/AutoTestService";
import ChargePointTestDTO from "../components/auto/model/ChargePointTestDTO";
import TestStepDTO from "../components/auto/model/TestStepDTO";
import TestStepper from "../components/auto/TestStepper";
import { Config } from "../Config";
import useErrorHandler from "../hooks/useErrorHandler";
import useRouter from "../hooks/useRouter";

function AutoTestPage() {
    const { params } = useRouter();

    const { onError } = useErrorHandler();
    const [testSteps, setTestSteps] = React.useState<TestStepDTO[]>([]);
    const [test, setTest] = React.useState<ChargePointTestDTO>();

    const wsRef = useRef<WebSocket>(null);

    const getTestSteps = async () => {
        const response = await AutoTestService.getSteps();
        const testSteps = response.data as TestStepDTO[];
        setTestSteps(testSteps);
    };

    useEffect(() => {
        void getTestSteps();
    }, []);

    const connect = (identity: String) => {
        if (wsRef.current) {
            return;
        }

        const url = Config.baseWssUrl + "/api/charge-point/" + identity + "/auto-test";
        const websocket = new WebSocket(url);
        console.log("connecting via WebSocket on " + url);

        wsRef.current = websocket;

        websocket.onmessage = (event: MessageEvent) => {
            const data = JSON.parse(event.data) as ChargePointTestDTO;
            setTest(data);
        };

        websocket.onclose = () => {
            console.log("websocket disconnected");
            wsRef.current = null;
        };
    };

    useEffect(() => {
        return () => {
            if (wsRef.current) {
                if (wsRef.current?.readyState === 1) {
                    wsRef.current.close();
                }
            }
        };
    }, [params.identity]);

    const handleStart = async () => {
        try {
            connect(params.identity);
        } catch (error) {
            onError(error, "Command Failed: ");
        }
    };

    const handleReset = () => {
        setTest(undefined);
        if (wsRef.current) {
            wsRef.current.close();
        }
    };

    // Function to determine if the test is done
    const isTestDone = (test: ChargePointTestDTO | undefined): boolean => {
        return test?.steps?.[test.steps.length - 1]?.name === "Done";
    };

    // Function to handle the download action
    const handleDownload = () => {
        // Clone the original test object to avoid mutating the original data
        const modifiedTest = { ...test };
        // Remove the last entry from the steps array
        modifiedTest.steps?.pop();

        const blob = new Blob([JSON.stringify(modifiedTest, null, 2)], { type: "application/json" });
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = `test-${test?.testId}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Grid2 container>
            <Grid2
                sx={{ mt: "6rem", mb: "6rem" }}
                size={{
                    xs: 10,
                    sm: 8,
                    md: 6,
                }}
                offset={{
                    xs: 1,
                    sm: 2,
                    md: 3,
                }}
            >
                <Card title="Auto Test" icon="Zap">
                    <Stack direction="column" spacing="4rem">
                        <div>
                            <Button
                                variant="contained"
                                disabled={test != undefined}
                                onClick={handleStart}
                                sx={{ mt: 1, mr: 1 }}
                                startIcon={<PlayArrow />}
                            >
                                Start
                            </Button>
                            <Button
                                variant="outlined"
                                disabled={test == undefined}
                                onClick={handleReset}
                                sx={{ mt: 1, mr: 1 }}
                                color="primary"
                                startIcon={<Replay />}
                            >
                                Reset
                            </Button>
                        </div>
                        {test ? <TestStepper testSteps={testSteps} test={test} key={test?.testId.toString()} /> : null}
                    </Stack>
                    {isTestDone(test) ? (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>All steps completed - Test finished</Typography>
                            <Button
                                variant="outlined"
                                onClick={handleDownload}
                                sx={{ mt: 1, mr: 1 }}
                                color="primary"
                                startIcon={<FileDownloadIcon />}
                            >
                                Export Results
                            </Button>
                        </Paper>
                    ) : null}
                </Card>
            </Grid2>
        </Grid2>
    );
}

export default AutoTestPage;
