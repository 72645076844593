import { ActionContext } from "app/components/action/ActionContext";
import { GetCompositeSchedule } from "app/components/action/model/Actions";
import { ActionType } from "app/components/action/model/ActionType";
import { ChargingRateUnitType } from "app/components/action/model/ChargingRateUnitType";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import useErrorHandler from "app/hooks/useErrorHandler";
import useRouter from "app/hooks/useRouter";
import OcppApiService from "app/services/OcppApiService";
import { Form, Formik, useFormikContext } from "formik";
import { Stack } from "lib/components";
import Button from "lib/components/Button/Button";
import { DialogContent, DialogFooter, DialogHeader } from "lib/components/Dialog";
import Dropdown from "lib/components/Dropdown/Dropdown";
import TextField from "lib/components/TextField/TextField";
import { ChangeEvent, useContext } from "react";
import * as yup from "yup";

const rateUnits = [
    {
        id: "A",
        value: "A",
    },
    {
        id: "W",
        value: "W",
    },
    {
        id: "VAR",
        value: "VAR (alpitronic only)",
    },
];

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const { values, handleChange, setFieldValue, isValid, errors } = useFormikContext<GetCompositeSchedule>();

    const handleRateUnitChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setFieldValue("rateUnit", value);
    };

    return (
        <Form>
            <DialogHeader>Get composite schedule</DialogHeader>
            <DialogContent>
                <Stack direction="column" spacing="3rem">
                    <TextField
                        label="Connector ID"
                        fullWidth
                        id="connectorId"
                        name="connectorId"
                        value={values.connectorId}
                        onChange={handleChange}
                        error={Boolean(errors.connectorId)}
                        helperText={errors.connectorId ? errors.connectorId : ""}
                    />
                    <Dropdown label="Rate unit" onChange={handleRateUnitChange} options={rateUnits} value={values.rateUnit} />
                    <TextField
                        label="Duration"
                        fullWidth
                        id="duration"
                        name="duration"
                        value={values.duration}
                        onChange={handleChange}
                        error={Boolean(errors.duration)}
                        helperText={errors.duration ? errors.duration : ""}
                    />
                </Stack>
            </DialogContent>
            <DialogFooter>
                <Button onClick={actionContext.onModalClose} variant="secondary">
                    Cancel
                </Button>
                <Button type="submit" variant="primary" disabled={!isValid}>
                    Submit
                </Button>
            </DialogFooter>
        </Form>
    );
};

const GetCompositeScheduleForm = () => {
    const { params } = useRouter();
    const actionContext = useContext(ActionContext);
    const { onError } = useErrorHandler();

    const handleSubmit = async (request: GetCompositeSchedule) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.GetCompositeSchedule, params.identity, request);
            await OcppApiService.getCompositeSchedule(request);
            actionContext.onModalClose();
        } catch (error) {
            onError(error, "Command Failed: ");
        } finally {
        }
    };

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                connectorId: undefined,
                duration: 60,
                rateUnit: ChargingRateUnitType.A,
            }}
            validationSchema={yup.object({
                connectorId: yup.string().required("Connector ID is a required field"),
            })}
            onSubmit={handleSubmit}
        >
            <InnerForm />
        </Formik>
    );
};

export default GetCompositeScheduleForm;
