import { ActionContext } from "app/components/action/ActionContext";
import ActionModal from "app/components/action/ActionModal";
import { ActionType } from "app/components/action/model/ActionType";
import AuthorizationModal from "app/components/authorization/AuthorizationModal";
import AuthenticationInformation from "app/components/chargepoint/AuthenticationInformation";
import BasicInformation from "app/components/chargepoint/BasicInformation";
import Connectors from "app/components/chargepoint/Connectors";
import ExtendedInformation from "app/components/chargepoint/ExtendedInformation";
import { ChargePoint } from "app/components/chargepoint/model/ChargePoint";
import Sidebar from "app/components/chargepoint/Sidebar";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";

import Header from "app/components/Header";
import Logs from "app/components/log/Logs";
import { LogsProvider } from "app/components/log/LogsContext";
import TabPanel from "app/components/TabPanel";
import { Config } from "app/Config";
import useRouter from "app/hooks/useRouter";
import useTracker from "app/hooks/useTracker";
import axios from "axios";
import { Box, Button, Stack, Tab, Tabs, Typography, Container } from "lib/components";
import Card from "lib/components/Card";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Divider, Grid2 } from "@mui/material";
import OCPPConfiguration from "app/components/chargepoint/OCPPConfiguration";

interface FeatureFlagsResponse {
    autoTest: boolean;
}

function SkeletonView(props: { onBack: () => void }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                paddingTop: "9rem",
            }}
        >
            <Header>
                <Stack direction="row">
                    <Button key="back" variant="text" onClick={props.onBack} icon="ArrowLeft">
                        Back to start
                    </Button>
                </Stack>
            </Header>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 4,
                }}
            >
                <Card title="Hmm..." icon="HelpCircle" sx={{ width: "45ch" }}>
                    <Stack sx={{ marginTop: "2rem", textAlign: "left" }} spacing="2rem">
                        <Typography>
                            Looks like your charge point hasn't appeared on our server yet, once it creates a successful connection to our
                            OCPP server, you will see information pop up here!
                        </Typography>
                    </Stack>
                </Card>
            </Box>
        </Box>
    );
}

const ChargePointPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get("tab") ?? "log";
    const { params, navigate } = useRouter();
    const { trackBoot } = useTracker();

    const [action, setAction] = useState<ActionType>();
    const [chargePoint, setChargePoint] = useState<ChargePoint | undefined>(undefined);
    const [isAutoTestEnabled, setIsAutoTestEnabled] = useState(false);

    const wsRef = useRef<WebSocket>(null);

    const connect = (identity: string) => {
        if (wsRef.current) {
            return;
        }
        const websocket = new WebSocket(Config.baseWssUrl + "/api/charge-point/" + identity);
        wsRef.current = websocket;

        websocket.onmessage = (event: MessageEvent) => {
            const data = JSON.parse(event.data) as ChargePoint;
            trackBoot(data);
            setChargePoint(data);
        };

        websocket.onclose = () => {
            console.log("websocket disconnected");
            wsRef.current = null;
            connect(identity);
        };
    };

    useEffect(() => {
        AnalyticsHelper.trackChargePointViewed(params.identity);
    }, []);

    useEffect(() => {
        connect(params.identity);
        return () => {
            if (wsRef.current) {
                if (wsRef.current?.readyState === 1) {
                    wsRef.current.close();
                }
            }
        };
    }, [params.identity]);

    useEffect(() => {
        // fetch feature flags from backend
        axios
            .get<FeatureFlagsResponse>(Config.baseApiUrl + "/api/v1/feature-flags")
            .then((response) => {
                const { autoTest } = response.data;
                setIsAutoTestEnabled(autoTest);
            })
            .catch((error) => {
                console.error("Failed to fetch feature flags", error);
            });
    }, []);

    const onBack = () => {
        navigate("/");
    };

    const onTabChange = (event: SyntheticEvent, newTab: string) => {
        setSearchParams({ tab: newTab });
    };

    if (!chargePoint) {
        return <SkeletonView onBack={onBack} />;
    }
    return (
        <Box
            sx={{
                width: "100%",
                margin: "0",
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "9rem",
            }}
        >
            <Header>
                <Stack direction="row" spacing="1.6rem">
                    {isAutoTestEnabled && (
                        <Button key="auto" variant="secondary" href={"/charge-points/" + params.identity + "/auto"}>
                            Auto Test
                        </Button>
                    )}
                    <Button key="back" variant="text" onClick={onBack} icon="ArrowLeft">
                        Back to start
                    </Button>
                </Stack>
            </Header>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flex: 1,
                    margin: 0,
                    padding: 0,
                }}
            >
                <Container
                    disableGutters
                    maxWidth="xl"
                    sx={{
                        display: "flex",
                        minHeight: "100%",
                        height: "inherit",
                    }}
                >
                    <Grid2 container={true} sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
                        <Grid2
                            size={9}
                            sx={{
                                padding: "4rem",
                            }}
                        >
                            <Stack direction="column" spacing="4rem">
                                <BasicInformation
                                    chargePointVendor={chargePoint.chargePointVendor}
                                    chargePointModel={chargePoint.chargePointModel}
                                    chargePointSerialNumber={chargePoint.chargePointSerialNumber}
                                    status={chargePoint.status}
                                />

                                <Box sx={{ width: "100%" }}>
                                    <LogsProvider identity={params.identity}>
                                        <Divider />
                                        <Tabs value={tab} onChange={onTabChange}>
                                            <Tab label="Log" value="log" />
                                            <Tab label="Details" value="details" />
                                            <Tab label="Configuration" value="configuration" />
                                        </Tabs>
                                        <TabPanel id="log" value={tab}>
                                            <Logs />
                                        </TabPanel>
                                        <TabPanel id="details" value={tab}>
                                            <AuthenticationInformation chargePoint={chargePoint} />
                                            <ExtendedInformation chargePoint={chargePoint} />
                                            <Connectors connectors={chargePoint.connectors} />
                                        </TabPanel>
                                        <TabPanel id="configuration" value={tab}>
                                            <OCPPConfiguration chargePoint={chargePoint} />
                                        </TabPanel>
                                    </LogsProvider>
                                </Box>
                            </Stack>
                        </Grid2>
                        <Grid2 size={3}>
                            <ActionContext.Provider
                                value={{
                                    action: action,
                                    updateAction: (action) => {
                                        setAction(action);
                                    },
                                    onModalClose: () => {
                                        setAction(undefined);
                                    },
                                }}
                            >
                                <Sidebar />
                                <ActionModal chargePoint={chargePoint} />
                            </ActionContext.Provider>
                        </Grid2>
                        <AuthorizationModal identity={params.identity} />
                    </Grid2>
                </Container>
            </Box>
        </Box>
    );
};

export default ChargePointPage;
