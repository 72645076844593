import { ChargePoint } from "app/components/chargepoint/model/ChargePoint";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";

const useTracker = () => {
    let bootTracked = false;

    const trackBoot = (chargePoint: ChargePoint) => {
        if (bootTracked) return;
        AnalyticsHelper.trackTestStart(
            chargePoint.identity,
            chargePoint.chargePointSerialNumber,
            chargePoint.chargePointVendor,
            chargePoint.chargePointModel,
            chargePoint.firmwareVersion
        );
        bootTracked = true;
    };

    return { trackBoot };
};

export default useTracker;
