import { ChargePointTransaction } from "app/components/chargepoint/model/ChargePointTransaction";
import Section from "app/components/Section";
import Box from "lib/components/Box/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DateUtils from "app/components/common/DateUtils";
import { IconButton } from "@mui/material";
import { Stop } from "@mui/icons-material";
import { useState } from "react";
import OcppApiService from "app/services/OcppApiService";
import useErrorHandler from "app/hooks/useErrorHandler";

const TransactionsTable = (props: { transactions?: ChargePointTransaction[] }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { onError } = useErrorHandler();
    const stopTransaction = async (transactionId: number) => {
        try {
            setLoading(true);
            await OcppApiService.forceStopTransaction(transactionId);
        } catch (error) {
            onError(error, "Stop transaction failed: ");
        } finally {
            setLoading(false);
        }
    };
    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            align: "center",
            flex: 0.5,
        },
        {
            field: "idTag",
            headerName: "ID Tag",
            flex: 1,
            align: "center",
        },
        {
            field: "meterStart",
            headerName: "Meter Start",
            flex: 0.8,
            align: "center",
        },
        {
            field: "startAt",
            headerName: "Start At",
            flex: 1.2,
            align: "center",
            renderCell: (params) => {
                return <div>{DateUtils.formatDate(params.value, "dd.MM.yyyy HH:mm:ss")}</div>;
            },
        },
        { field: "meterStop", headerName: "Meter Stop", align: "center", flex: 0.8 },
        {
            field: "stopAt",
            headerName: "Stop At",
            flex: 1.2,
            renderCell: (params) => {
                return <div>{DateUtils.formatDate(params.value, "dd.MM.yyyy HH:mm:ss")}</div>;
            },
        },
        { field: "reason", headerName: "Reason", align: "center", flex: 1 },
        {
            field: "actins",
            headerName: "Actions",
            align: "center",
            flex: 0.5,
            renderCell: (params) => {
                return params.row.stopAt ? null : (
                    <IconButton
                        size={"small"}
                        loading={loading}
                        onClick={() => {
                            void stopTransaction(params.row.id);
                        }}
                    >
                        <Stop />
                    </IconButton>
                );
            },
        },
    ];
    return (
        <>
            <Box>
                <Section title="Transactions" icon="GitCommit" />
            </Box>
            <DataGrid disableRowSelectionOnClick columns={columns} rows={props.transactions} hideFooter />
        </>
    );
};
export default TransactionsTable;
