import { Color } from "../types/color";
import theme from "./theme";

const getColor = (color: Color, isInverted = false) => {
    switch (color) {
        case "coral":
            return isInverted ? theme.palette.white : theme.palette.coral[100];
        case "sand":
            return isInverted ? theme.palette.white : theme.palette.sand[100];
        case "ocean":
            return isInverted ? theme.palette.white : theme.palette.ocean[100];
        case "yellow":
            return isInverted ? theme.palette.white : theme.palette.yellow[100];
        case "red":
            return isInverted ? theme.palette.white : theme.palette.red[100];
        case "green":
            return isInverted ? theme.palette.white : theme.palette.green[100];
        case "plum":
            return isInverted ? theme.palette.white : theme.palette.plum[100];
        case "raspberry":
            return isInverted ? theme.palette.white : theme.palette.raspberry[100];
        case "night":
        default:
            return isInverted ? theme.palette.white : theme.palette.night[100];
    }
};

export default getColor;
