import { ChargePoint } from "app/components/chargepoint/model/ChargePoint";
import getColor from "app/components/common/getColor";
import getStatusIcon from "app/components/common/getStatusIcon";
import Card, { CardProps } from "lib/components/Card";
import { Grid2 } from "@mui/material";
interface BasicInformationProps {
    chargePointVendor: ChargePoint["chargePointVendor"];
    chargePointModel: ChargePoint["chargePointModel"];
    chargePointSerialNumber: ChargePoint["chargePointSerialNumber"];
    status: ChargePoint["status"];
}

const BasicInformation = ({
    chargePointVendor = "",
    chargePointModel = "",
    chargePointSerialNumber = "",
    status,
}: BasicInformationProps) => {
    const cards = [
        {
            title: "Vendor",
            description: chargePointVendor,
            icon: "Tool",
            color: "ocean",
        },
        {
            title: "Model",
            description: chargePointModel,
            icon: "Speaker",
            color: "ocean",
        },
        {
            title: "Serial number",
            description: chargePointSerialNumber,
            icon: "Key",
            color: "ocean",
        },
        {
            title: "Status",
            description: status,
            color: getColor(status),
            icon: getStatusIcon(status),
        },
    ] as CardProps[];

    return (
        <Grid2 container spacing={2}>
            {cards.map(({ title, description, color, icon }, index) => (
                <Grid2 size={{ xs: 12, md: 6, lg: 3 }} key={index}>
                    <Card title={title} description={description} color={color} icon={icon} />
                </Grid2>
            ))}
        </Grid2>
    );
};

export default BasicInformation;
