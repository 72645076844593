import {
    ChangeConfiguration,
    ClearChargingProfile,
    DataTransfer,
    Disconnect,
    GetCompositeSchedule,
    GetConfiguration,
    GetDiagnosticsRequest,
    RemoteStartTransaction,
    RemoteStopTransaction,
    Reset,
    SendLocalList,
    SetChargingProfile,
    SetHeartbeatInterval,
    TriggerMessage,
    UnlockConnectorRequest,
    UpdateFirmware,
} from "app/components/action/model/Actions";
import { AuthorizationPayload } from "app/components/authorization/AuthorizationPayload";
import { Config } from "app/Config";
import axios, { AxiosResponse } from "axios";

export default class OcppApiService {
    private static client = axios.create({
        baseURL: Config.baseApiUrl,
        headers: {
            "Content-type": "application/json",
        },
        withCredentials: true,
    });

    static async authorize(identity: string, request: AuthorizationPayload): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + identity + "/auth", {
            uuid: request.uuid,
            id_tag: request.id_tag,
        });
    }

    static async changeConfiguration(request: ChangeConfiguration): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/configuration/set", request.keys);
    }

    static async clearChargingProfile(request: ClearChargingProfile): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/charging-profile/clear", request, {
            params: {
                connectorId: request.connectorId,
            },
        });
    }

    static async disconnect(request: Disconnect): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/disconnect", {});
    }

    static async getCompositeSchedule(request: GetCompositeSchedule): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/charging-profile/read", request, {
            params: {
                connectorId: request.connectorId,
            },
        });
    }

    static async getConfiguration(request: GetConfiguration): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/configuration/get", request.keys);
    }

    static async remoteStartTransaction(request: RemoteStartTransaction): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/connector/" + request.connectorId + "/transaction", {
            idTag: request.idTag,
        });
    }

    static async remoteStopTransaction(request: RemoteStopTransaction): Promise<AxiosResponse> {
        return OcppApiService.client.delete("/api/charge-point/" + request.identity + "/connector/" + request.connectorId + "/transaction");
    }

    static async reset(request: Reset): Promise<AxiosResponse> {
        return OcppApiService.client.post(
            "/api/charge-point/" + request.identity + "/reset",
            {},
            {
                params: {
                    reset_type: request.reset_type,
                },
            }
        );
    }

    static async dataTransfer(request: DataTransfer): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/data-transfer", request);
    }

    static async sendLocalList(request: SendLocalList): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/local-auth-list", request.keys);
    }

    static async setChargingProfile(request: SetChargingProfile): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/charging-profile", request, {
            params: {
                connectorId: request.connectorId,
            },
        });
    }

    static async triggerMessage(request: TriggerMessage): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/remote-trigger", request, {
            params: {
                connectorId: request.connectorId,
            },
        });
    }

    static async updateFirmware(request: UpdateFirmware): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/update-firmware", request);
    }

    static async setHeartbeatInterval(request: SetHeartbeatInterval): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/set-heartbeat-interval", request);
    }

    static async getDiagnostics(request: GetDiagnosticsRequest): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/get-diagnostics", request);
    }

    static async unlockConnector(request: UnlockConnectorRequest): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + request.identity + "/connectors/" + request.connectorId + "/unlock");
    }

    static async forceStopTransaction(transactionId: number): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/transaction/" + transactionId + "/stop");
    }

    static async startNewTest(identity: String): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + identity + "/auto-test");
    }

    static async continueTest(identity: String, testId: String): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + identity + "/auto-test/" + testId);
    }

    static async authorizationRemove(identity: string): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + identity + "/authentication/remove");
    }

    static async authorizationReset(identity: string): Promise<AxiosResponse> {
        return OcppApiService.client.post("/api/charge-point/" + identity + "/authentication/reset");
    }
}
