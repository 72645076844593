import Box from "@mui/material/Box";
import * as icons from "react-feather";

export const iconOptions = Object.keys(icons) as IconName[];

export type IconName = keyof typeof icons;

export const iconSizes = ["small", "medium", "large", "inherit"];

export type IconSize = "small" | "medium" | "large" | "inherit";

export interface IconProps {
    name: IconName;
    size?: IconSize;
}

const getSize = (size?: IconSize) => {
    switch (size) {
        case "small":
            return "1.6rem";
        case "medium":
            return "2rem";
        case "large":
            return "3.5rem";
        case "inherit":
        default:
            return "100%";
    }
};

const Icon = ({ name, size = "inherit" }: IconProps) => {
    const sizeInRems = getSize(size);
    const Component = icons[name];
    return (
        <Box
            sx={{
                width: sizeInRems,
                height: sizeInRems,
                svg: {
                    width: "100%",
                    height: "100%",
                },
            }}
        >
            <Component />
        </Box>
    );
};

export default Icon;
