import Box from "@mui/material/Box";
import MuiDialog from "@mui/material/Dialog";
import MuiDialogFooter from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogHeader from "@mui/material/DialogTitle";
import React, { forwardRef, Ref } from "react";

import Avatar from "../Avatar";
import Icon from "../Icon";
import Typography from "../Typography";

import { DialogProps } from "./types";

const Dialog = (
    { children, fullWidth = true, onClose, isOpen, title, description, icon, ...props }: DialogProps,
    ref: Ref<HTMLDivElement>
): React.JSX.Element => {
    const withDetails = !!icon || !!title || !!description;

    return (
        <MuiDialog
            maxWidth="lg"
            ref={ref}
            fullWidth={fullWidth}
            open={isOpen}
            onClose={onClose}
            sx={{
                ...(!withDetails && {
                    ".MuiDialog-paper": {
                        padding: 0,
                    },
                }),
            }}
            {...props}
        >
            <Box>
                {withDetails && (
                    <MuiDialogContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            marginBottom: "3.2rem",
                            padding: "0 !important",
                        }}
                    >
                        {icon && (
                            <Box sx={{ marginBottom: "3.2rem" }}>
                                <Avatar>
                                    <Icon name={icon} />
                                </Avatar>
                            </Box>
                        )}
                        {title && <Typography variant="h5">{title}</Typography>}
                        {description && (
                            <Typography variant="body1" sx={{ marginTop: title ? "1rem" : "0" }}>
                                {description}
                            </Typography>
                        )}
                    </MuiDialogContent>
                )}
            </Box>
            {children}
        </MuiDialog>
    );
};

export const DialogHeader = MuiDialogHeader;
export const DialogContent = MuiDialogContent;
export const DialogFooter = MuiDialogFooter;

export default forwardRef(Dialog);
