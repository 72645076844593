import * as Sentry from "@sentry/react";
import App from "app/app";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import { Config } from "app/Config";
import React from "react";
import ReactDOM from "react-dom/client";
import ErrorPage from "lib/ErrorBoundary/ErrorPage";
import { BrowserRouter } from "react-router-dom";

if (Config.sentryDsn) {
    Sentry.init({
        dsn: Config.sentryDsn,
        integrations: [Sentry.httpClientIntegration],
        tracesSampleRate: 0.0,
        environment: Config.stage,
    });
}

AnalyticsHelper.setup();

const SentryErrorBoundary = ({ children }: { children: React.ReactNode }) => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error }) => {
                return <ErrorPage type="500" error={error} />;
            }}
            onError={(error: unknown, componentStack: string | undefined, eventId: string) => {
                console.log("ErrorBoundary caught an error:", error, componentStack, eventId);
                if (
                    (error as Error).message.includes("Failed to fetch dynamically imported module") ||
                    (error as Error).message.includes("Importing a module script failed")
                ) {
                    Sentry.addBreadcrumb({
                        category: "debug",
                        message: "Reloading page due to failed module import",
                        data: {
                            href: window.location.href,
                            error: (error as Error).message,
                        },
                    });
                    window.location.reload();
                }
            }}
            showDialog
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <BrowserRouter>
            <SentryErrorBoundary>
                <App />
            </SentryErrorBoundary>
        </BrowserRouter>
    </React.StrictMode>
);
