import { IconButton } from "@mui/material";
import Grid2 from "@mui/material/Grid2";
import { Config } from "app/Config";
import { Form, Formik, useFormikContext } from "formik";
import { Stack, Typography } from "lib/components";
import Button from "lib/components/Button";
import Card from "lib/components/Card";
import TextField from "lib/components/TextField";
import { useSnackbar } from "notistack";
import * as React from "react";
import { Copy } from "react-feather";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

type Values = typeof initialValues;

const initialValues = {
    identity: "",
};

function InnerForm() {
    const { enqueueSnackbar } = useSnackbar();

    const { values, errors, touched, handleChange, isValid } = useFormikContext<Values>();

    const copyToClipboard = React.useCallback(async () => {
        await navigator.clipboard.writeText(Config.baseWssUrl);
        enqueueSnackbar("Copied to clipboard!");
    }, [Config.baseWssUrl]);

    return (
        <Form>
            <Stack sx={{ marginTop: "2rem", textAlign: "left" }} spacing="2.4rem">
                <Typography>Below you'll find a URL where you can connect your charge point to our testing environment:</Typography>
                <TextField
                    fullWidth
                    value={Config.baseWssUrl}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={copyToClipboard}>
                                <Copy />
                            </IconButton>
                        ),
                    }}
                />
                <Typography>
                    Once the charge point has connected to our server please type in the identity or serial used to connect to the server.
                </Typography>
                <TextField
                    fullWidth
                    id="identity"
                    name="identity"
                    label="Identity"
                    value={values.identity}
                    onChange={handleChange}
                    error={touched.identity && Boolean(errors.identity)}
                    helperText={touched.identity && errors.identity ? errors.identity : "This is usually the charge points serial number"}
                />
                <Button type="submit" variant="primary" disabled={!isValid} fullWidth>
                    Connect
                </Button>
            </Stack>
        </Form>
    );
}

function IdentityPage() {
    const navigate = useNavigate();

    const handleSubmit = ({ identity }: Values) => {
        let trimmedIdentity = identity.trim();
        navigate(`/charge-points/${trimmedIdentity}`);
    };

    return (
        <Grid2 container>
            <Grid2
                sx={{ mt: "6rem", mb: "6rem" }}
                size={{
                    md: 3,
                }}
                offset={{
                    md: 4.5,
                }}
            >
                <Card title="OCPP 1.6 Toolkit" icon="Zap">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={yup.object({
                            identity: yup.string().required("Identity is a required field"),
                        })}
                        onSubmit={handleSubmit}
                    >
                        <InnerForm />
                    </Formik>
                </Card>
            </Grid2>
        </Grid2>
    );
}

export default IdentityPage;
