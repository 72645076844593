import { ConnectorMeasurement } from "app/components/chargepoint/model/ConnectorMeasurement";
import Box from "lib/components/Box/Box";
import Section from "app/components/Section";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const MeasurementsTable = (props: { measurements: ConnectorMeasurement[] }) => {
    const columns: GridColDef[] = [
        { field: "measurand", headerName: "Measurand", flex: 1.5, align: "center" },
        { field: "phase", headerName: "Phase", flex: 0.5, align: "center" },
        { field: "value", headerName: "Value", flex: 0.8, align: "center" },
        { field: "unit", headerName: "Unit", flex: 1, align: "center" },
        { field: "context", headerName: "Context", flex: 1, align: "center" },
        { field: "format", headerName: "Format", flex: 0.8, align: "center" },
        { field: "location", headerName: "Location", flex: 0.5, align: "center" },
    ];

    return (
        <>
            <Box sx={{ pt: "1rem" }}>
                <Section title="Measurements" icon="Activity" />
            </Box>
            <DataGrid hideFooter rows={props.measurements} columns={columns} disableRowSelectionOnClick />
        </>
    );
};
export default MeasurementsTable;
