import { Color } from "../types/color";
import theme from "./theme";

const getBackgroundColor = (color: Color, isInverted: boolean = false) => {
    switch (color) {
        case "coral":
            return isInverted ? theme.palette.coral[100] : theme.palette.coral[20];
        case "sand":
            return isInverted ? theme.palette.sand[100] : theme.palette.sand[20];
        case "ocean":
            return isInverted ? theme.palette.ocean[100] : theme.palette.ocean[5];
        case "yellow":
            return isInverted ? theme.palette.yellow[100] : theme.palette.yellow[8];
        case "red":
            return isInverted ? theme.palette.red[100] : theme.palette.red[8];
        case "green":
            return isInverted ? theme.palette.green[100] : theme.palette.green[8];
        case "plum":
            return isInverted ? theme.palette.plum[100] : theme.palette.plum[10];
        case "raspberry":
            return isInverted ? theme.palette.raspberry[100] : theme.palette.raspberry[10];
        case "night":
        default:
            return isInverted ? theme.palette.night[100] : theme.palette.night[20];
    }
};

export default getBackgroundColor;
