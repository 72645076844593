import { ActionContext } from "app/components/action/ActionContext";
import ChangeConfigurationForm from "app/components/action/forms/ChangeConfigurationForm";
import ClearChargingProfileForm from "app/components/action/forms/ClearChargingProfileForm";
import DataTransferForm from "app/components/action/forms/DataTransferForm";
import DisconnectForm from "app/components/action/forms/DisconnectForm";
import GetCompositeScheduleForm from "app/components/action/forms/GetCompositeScheduleForm";
import GetConfigurationForm from "app/components/action/forms/GetConfigurationForm";
import GetDiagnosticsForm from "app/components/action/forms/GetDiagnosticsForm";
import RemoteStartTransactionForm from "app/components/action/forms/RemoteStartTransactionForm";
import RemoteStopTransactionForm from "app/components/action/forms/RemoteStopTransactionForm";
import ResetForm from "app/components/action/forms/ResetForm";
import SendLocalListForm from "app/components/action/forms/SendLocalListForm";
import SetChargingProfileForm from "app/components/action/forms/SetChargingProfileForm";
import SetHeartbeatIntervalForm from "app/components/action/forms/SetHeartbeatIntervalForm";
import TriggerMessageForm from "app/components/action/forms/TriggerMessageForm";
import UnlockConnectorForm from "app/components/action/forms/UnlockConnectorForm";
import UpdateFirmwareForm from "app/components/action/forms/UpdateFirmwareForm";
import { ActionType } from "app/components/action/model/ActionType";
import Dialog from "lib/components/Dialog";
import { useContext } from "react";
import { ChargePoint } from "app/components/chargepoint/model/ChargePoint";

function Modal(props: { action?: ActionType; chargePoint: ChargePoint }) {
    switch (props.action) {
        case ActionType.Reset:
            return <ResetForm />;
        case ActionType.UnlockConnector:
            return <UnlockConnectorForm />;
        case ActionType.DataTransfer:
            return <DataTransferForm />;
        case ActionType.RemoteStartTransaction:
            return <RemoteStartTransactionForm />;
        case ActionType.RemoteStopTransaction:
            return <RemoteStopTransactionForm />;
        case ActionType.TriggerMessage:
            return <TriggerMessageForm />;
        case ActionType.SendLocalList:
            return <SendLocalListForm />;
        case ActionType.SetChargingProfile:
            return <SetChargingProfileForm chargePoint={props.chargePoint} />;
        case ActionType.ClearChargingProfile:
            return <ClearChargingProfileForm />;
        case ActionType.GetCompositeSchedule:
            return <GetCompositeScheduleForm />;
        case ActionType.GetConfiguration:
            return <GetConfigurationForm />;
        case ActionType.GetDiagnostics:
            return <GetDiagnosticsForm />;
        case ActionType.ChangeConfiguration:
            return <ChangeConfigurationForm />;
        case ActionType.UpdateFirmware:
            return <UpdateFirmwareForm />;
        case ActionType.SetHeartbeatInterval:
            return <SetHeartbeatIntervalForm />;
        case ActionType.Disconnect:
            return <DisconnectForm />;
        default:
            return null;
    }
}

export default function ActionModal(props: { chargePoint: ChargePoint }) {
    const actionContext = useContext(ActionContext);
    return (
        <Dialog isOpen={actionContext.action != undefined} onClose={actionContext.onModalClose}>
            <Modal action={actionContext.action} chargePoint={props.chargePoint} />
        </Dialog>
    );
}
