export enum ActionType {
    Reset = "Reset",
    DataTransfer = "DataTransfer",
    RemoteStartTransaction = "RemoteStartTransaction",
    RemoteStopTransaction = "RemoteStopTransaction",
    TriggerMessage = "TriggerMessage",
    SendLocalList = "SendLocalList",
    SetChargingProfile = "SetChargingProfile",
    ClearChargingProfile = "ClearChargingProfile",
    GetCompositeSchedule = "GetCompositeSchedule",
    GetConfiguration = "GetConfiguration",
    GetDiagnostics = "GetDiagnostics",
    ChangeConfiguration = "ChangeConfiguration",
    UpdateFirmware = "UpdateFirmware",
    SetHeartbeatInterval = "SetHeartbeatInterval",
    UnlockConnector = "UnlockConnector",
    Disconnect = "Disconnect",
}
