import Box from "../Box";

import { PaperProps } from "./types";

const Paper = ({ children, elevated = true, sx }: PaperProps) => {
    return (
        <Box
            sx={{
                backgroundColor: "white",
                borderRadius: "0.8rem",
                width: "inherit",
                height: "inherit",
                color: "night.60",
                padding: "3rem",
                border: 0,
                ...(elevated && {
                    border: "1px solid",
                    borderColor: "night.40",
                    boxShadow: "0px 0.8rem 2rem rgba(52, 52, 87, 0.04)",
                }),
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export default Paper;
