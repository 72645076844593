import { ChargePointConnector } from "app/components/chargepoint/model/ChargePointConnector";
import Box from "lib/components/Box";
import Connector from "app/components/chargepoint/Connector";

interface ConnectorsProps {
    connectors: ChargePointConnector[];
}

const Connectors = ({ connectors }: ConnectorsProps) => {
    return (
        <Box sx={{ width: "100%", marginTop: "2rem" }}>
            {connectors.map((connector, index) => (
                <Box key={connector.connectorId}>
                    <Connector connector={connector} index={index} />
                </Box>
            ))}
        </Box>
    );
};

export default Connectors;
