import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { useState } from "react";

import Box from "../Box";
import Icon from "../Icon";

import { AccordionProps } from "./types";

const boxShadow = "0px 0.8rem 2rem rgba(52, 52, 87, 0.04)";

const Accordion = ({ isOpen, isDefaultOpen, onClick, summary, details, isElevated = false, isLight = false, sx }: AccordionProps) => {
    const [isLocalOpen, setIsLocalOpen] = useState(false);

    const expanded = isOpen !== undefined ? isOpen : isLocalOpen;

    const handleToggle = () => setIsLocalOpen(!isLocalOpen);

    const handleChange = onClick !== undefined ? onClick : handleToggle;

    return (
        <MuiAccordion
            defaultExpanded={isDefaultOpen}
            expanded={isDefaultOpen ? undefined : expanded}
            onChange={handleChange}
            square
            disableGutters
            elevation={0}
            sx={{
                border: "1px solid",
                borderColor: "night.40",
                backgroundColor: "white",
                borderRadius: "0.8rem",
                ...(isElevated && {
                    boxShadow,
                }),
                ...sx,
            }}
        >
            <MuiAccordionSummary
                expandIcon={<Icon name="ChevronDown" />}
                sx={{
                    flexDirection: "row-reverse",
                    minHeight: "0rem",
                    padding: isLight ? "0.8rem 1.6rem" : "1.6rem",
                    ".MuiAccordionSummary-content": {
                        margin: "0 0 0 0.4rem",
                        padding: "0",
                    },
                    ".MuiAccordionSummary-expandIconWrapper": {
                        marginLeft: "-6px",
                        "& > div": {
                            display: "flex",
                            alignItems: "center",
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flex: 1,
                    }}
                >
                    {summary}
                </Box>
            </MuiAccordionSummary>
            <MuiAccordionDetails
                sx={{
                    padding: "0 1.6rem 1.6rem",
                }}
            >
                {details}
            </MuiAccordionDetails>
        </MuiAccordion>
    );
};

export default Accordion;
