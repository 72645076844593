import CpiThemeProvider from "lib/components/CpiThemeProvider";
import { ConfirmProvider } from "material-ui-confirm";
import Box from "@mui/material/Box";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "lib/ErrorBoundary/ErrorBoundary";
import { CircularProgress } from "@mui/material";

const MontaAppLayout = () => {
    return (
        <CpiThemeProvider>
            <ConfirmProvider
                defaultOptions={{
                    dialogProps: { sx: { ".MuiDialog-paper": { padding: "0" } } },
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "100vw",
                        overflowX: "hidden",
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Suspense fallback={<CircularProgress />}>
                        <ErrorBoundary>
                            <Outlet />
                        </ErrorBoundary>
                    </Suspense>
                </Box>
            </ConfirmProvider>
        </CpiThemeProvider>
    );
};
export default MontaAppLayout;
