import * as Sentry from "@sentry/react";
import React, { Component, ErrorInfo, ReactNode } from "react";
import MontaException from "lib/ErrorBoundary/MontaException";
import ErrorPage, { ErrorPageType } from "lib/ErrorBoundary/ErrorPage";

interface HttpErrorBoundaryProps {
    children?: ReactNode;
}

interface HttpErrorBoundaryState {
    error?: Error;
}

/**
 * Responsible for catching HTTP errors and displaying the appropriate error page within the [MontaAppLayout] component.
 */
class HttpErrorBoundary extends Component<HttpErrorBoundaryProps, HttpErrorBoundaryState> {
    public state: HttpErrorBoundaryState = {
        error: undefined,
    };

    public static getDerivedStateFromError(error: Error): HttpErrorBoundaryState {
        return { error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.log("HttpErrorBoundary caught an error:", error, errorInfo);
        // Log all non-MontaException errors to Sentry
        if (!(error instanceof MontaException)) {
            Sentry.captureException(error);
        }
    }

    private getErrorType(): ErrorPageType {
        if (this.state.error instanceof MontaException) {
            const statusCode = this.state.error.statusCode;
            switch (statusCode) {
                case 403:
                    return "403";
                case 404:
                    // Specific for missing resources
                    return "404-resource";
                case 500:
                    return "500";
                default:
                    return "default";
            }
        }
        // Fallback for unexpected errors
        return "default";
    }

    public render() {
        if (this.state.error) {
            return <ErrorPage type={this.getErrorType()} />;
        } else {
            return this.props.children;
        }
    }
}

export default HttpErrorBoundary;
