import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from "@mui/material";
import React from "react";

export type VariantTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "inherit";

export type ColorTypes = "primary" | "secondary" | "textPrimary" | "textSecondary" | "inherit";

export interface TypographyProps extends Omit<MuiTypographyProps, "component" | "variant" | "variantMapping" | "color"> {
    variant?: VariantTypes;
    color?: ColorTypes;
    align?: "inherit" | "left" | "center" | "right";
    "data-testid"?: string;
}

const Typography = ({ variant = "body1", ...props }: TypographyProps): React.JSX.Element => {
    return <MuiTypography variant={variant} {...props} />;
};

export default Typography;
