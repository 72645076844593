import StringUtils from "app/components/common/StringUtils";
import { v4 as uuidv4 } from "uuid";

export default class AnalyticsHelper {
    private static deviceIdKey = "device_id";
    private static companyNameKey = "company_name";
    private static contactEmailKey = "contact_email";
    private static isEnabled = false;

    static setup() {
        if (!this.isEnabled) {
            console.log("Analytics disabled");
            return;
        }

        // Set company details if previously set
        this.setCompanyDetails(this.getCompanyName(), this.getContactEmail());
    }

    static setCompanyDetails(companyName: string | null, contactEmail: string | null) {}

    static trackCommandStart(ocppCommandType: string, chargePointIdentity: string, payload: any | null = null) {}

    static trackChargePointViewed(chargePointIdentity: string) {}

    static trackTestStart(
        chargePointIdentity: string,
        serial: string | undefined,
        brand: string | undefined,
        model: string | undefined,
        firmwareVersion: string | undefined
    ) {}

    static hasDetails(): boolean {
        if (!this.isEnabled) {
            console.log("Analytics disabled");
            return true;
        }
        return !StringUtils.isBlankOrNull(this.getCompanyName()) && !StringUtils.isBlankOrNull(this.getContactEmail());
    }

    static getCompanyName(): string | null {
        return window.localStorage.getItem(this.companyNameKey);
    }

    private static getUniqueIdentifier(): string {
        const uuid = window.localStorage.getItem(this.deviceIdKey);
        if (!uuid) {
            const newUuid = uuidv4();
            window.localStorage.setItem(this.deviceIdKey, newUuid.toString());
            return newUuid;
        }
        return uuid;
    }

    private static setCompanyName(companyName: string | null) {
        this.setLocalStorage(this.companyNameKey, companyName);
    }

    private static setContactEmail(contactEmail: string | null) {
        this.setLocalStorage(this.contactEmailKey, contactEmail);
    }

    private static getContactEmail(): string | null {
        return window.localStorage.getItem(this.contactEmailKey);
    }

    private static setLocalStorage(key: string, value: string | null) {
        if (value) {
            window.localStorage.setItem(key, value);
        } else {
            window.localStorage.removeItem(key);
        }
    }
}
