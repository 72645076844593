import { Accordion, Box, Card, CardProps, List, ListItem, ListItemText, Stack } from "lib/components";
import Typography from "lib/components/Typography";
import { ChargePointConnector } from "app/components/chargepoint/model/ChargePointConnector";
import Grid2 from "@mui/material/Grid2";
import getColor from "app/components/common/getColor";
import getStatusIcon from "app/components/common/getStatusIcon";
import DateUtils from "app/components/common/DateUtils";
import TransactionsTable from "app/components/chargepoint/TransactionsTable";
import MeasurementsTable from "app/components/chargepoint/MeasurementsTable";

interface ConnectorProps {
    index: number;
    connector: ChargePointConnector;
}

const Connector: React.FC<ConnectorProps> = (props: ConnectorProps) => {
    const data = [
        [
            {
                key: "Error code",
                value: props.connector.errorCode,
            },
            {
                key: "Vendor error code",
                value: props.connector.vendorErrorCode,
            },
        ],
        [
            {
                key: "Updated at",
                value: DateUtils.formatDate(props.connector.updatedAt, "dd.MM.yyyy HH:mm:ss"),
            },
            {
                key: "Created at",
                value: DateUtils.formatDate(props.connector.createdAt, "dd.MM.yyyy HH:mm:ss"),
            },
        ],
    ];
    const cards = [
        {
            title: "Info",
            description: props.connector.info,
            icon: "Info",
            color: "ocean",
        },
        {
            title: "Meter Wh",
            description: props.connector.meterWh,
            icon: "Zap",
            color: "coral",
        },
        {
            title: "Vendor ID",
            description: props.connector.vendorId,
            icon: "Shield",
            color: "night",
        },
        {
            title: "Status",
            description: props.connector.status,
            color: getColor(props.connector.status),
            icon: getStatusIcon(props.connector.status),
        },
    ] as CardProps[];

    return (
        <Accordion
            summary={
                <Box>
                    <Typography variant="body2">{props.connector.chargePointIdentity + " Connector " + (props.index + 1)}</Typography>
                </Box>
            }
            details={
                <Stack direction={"column"} spacing={"2rem"}>
                    <Box>
                        <Grid2 container>
                            {cards.map(({ title, description, color, icon }, index) => (
                                <Grid2 key={index} size={{ xs: 12, md: 6, xl: 6 }}>
                                    <Card title={title} description={description} color={color} icon={icon} />
                                </Grid2>
                            ))}
                        </Grid2>
                    </Box>
                    <Box>
                        <Grid2 container>
                            {data.map((rows, rowIndex) => (
                                <Grid2 key={rowIndex} size={{ xs: 12, md: 6, xl: 6 }}>
                                    <List>
                                        {rows.map(({ key, value }, rowIndex) => (
                                            <ListItem
                                                key={key}
                                                divider={rowIndex + 1 !== rows.length}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <ListItemText sx={{ textAlign: "left" }}>{key}</ListItemText>
                                                <ListItemText sx={{ textAlign: "right" }}>{value}</ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid2>
                            ))}
                        </Grid2>
                    </Box>
                    <TransactionsTable transactions={props.connector.transactions} />
                    <MeasurementsTable measurements={props.connector.measurements} />
                </Stack>
            }
        />
    );
};
export default Connector;
