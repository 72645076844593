import useErrorHandler from "app/hooks/useErrorHandler";
import useRouter from "app/hooks/useRouter";
import OcppApiService from "app/services/OcppApiService";

const useAuthentication = () => {
    const { params } = useRouter();
    const { onError } = useErrorHandler();

    const removePassword = async () => {
        try {
            await OcppApiService.authorizationRemove(params.identity);
        } catch (error) {
            onError(error, "Authentication remove failed: ");
        }
    };
    const resetPassword = async () => {
        try {
            await OcppApiService.authorizationReset(params.identity);
        } catch (error) {
            onError(error, "Authentication reset failed: ");
        }
    };

    return {
        removePassword,
        resetPassword,
    };
};

export default useAuthentication;
