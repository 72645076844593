import { Config } from "app/Config";
import axios, { AxiosResponse } from "axios";

export default class AutoTestService {
    private static client = axios.create({
        baseURL: Config.baseApiUrl,
        headers: {
            "Content-type": "application/json",
        },
        withCredentials: true,
    });

    static async getSteps(): Promise<AxiosResponse> {
        return AutoTestService.client.get("/api/auto-test/steps");
    }
}
