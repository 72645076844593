import Grid2 from "@mui/material/Grid2";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import { Form, Formik, useFormikContext } from "formik";
import { List, ListItem, Stack, Typography } from "lib/components";
import Button from "lib/components/Button";
import Card from "lib/components/Card";
import TextField from "lib/components/TextField/TextField";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

interface ContactFormState {
    companyName: string;
    companyEmail: string;
}

function InnerForm() {
    const { values, handleChange, isValid, errors } = useFormikContext<ContactFormState>();

    return (
        <Form>
            <Stack direction="column" spacing={2}>
                <Typography align={"left"}>
                    We are excited to have you participate in our upcoming compatibility testing process.
                </Typography>
                <Typography align={"left"}>
                    To ensure a successful and seamless testing process, please make sure that you have the following before starting:
                </Typography>
                <List>
                    <ListItem>1. A stable internet connection</ListItem>
                    <ListItem>2. Car or car simulator</ListItem>
                    <ListItem>3. Charge Point</ListItem>
                    <ListItem>4. Contactless charge key (RFID) if charging via RFID is supported</ListItem>
                </List>
                <Typography align={"left"}>Before we continue the test, we just need to collect some contact information!</Typography>
                <TextField
                    label="Company Name"
                    fullWidth
                    id="companyName"
                    name="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                    error={Boolean(errors.companyName)}
                    helperText={errors.companyName ? errors.companyName : ""}
                />
                <TextField
                    label="Email"
                    fullWidth
                    id="companyEmail"
                    name="companyEmail"
                    value={values.companyEmail}
                    onChange={handleChange}
                    error={Boolean(errors.companyEmail)}
                    helperText={errors.companyEmail ? errors.companyEmail : ""}
                />
                <Button type="submit" variant="primary" disabled={!isValid}>
                    Submit
                </Button>
            </Stack>
        </Form>
    );
}

function StartPage() {
    const navigate = useNavigate();

    const handleSubmit = async (request: ContactFormState) => {
        AnalyticsHelper.setCompanyDetails(request.companyName, request.companyEmail);
        navigate("/identity");
    };

    return (
        <Grid2 container>
            <Grid2
                sx={{ mt: "6rem", mb: "6rem" }}
                size={{
                    md: 3,
                }}
                offset={{
                    md: 4.5,
                }}
            >
                <Card title="OCPP 1.6 Toolkit" icon="Zap">
                    <Formik
                        initialValues={{
                            companyName: "",
                            companyEmail: "",
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={yup.object({
                            companyName: yup.string().required("Company name field is required"),
                            companyEmail: yup.string().required("Email field is required").email("Email is not valid"),
                        })}
                    >
                        <InnerForm />
                    </Formik>
                </Card>
            </Grid2>
        </Grid2>
    );
}

export default StartPage;
