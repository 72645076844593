import { Divider } from "@mui/material";
import { ActionContext } from "app/components/action/ActionContext";
import { ActionType } from "app/components/action/model/ActionType";
import Section from "app/components/Section";
import useAuthentication from "app/hooks/useAuthentication";
import { Stack } from "lib/components";
import Box from "lib/components/Box";
import Button from "lib/components/Button";
import { useContext, useMemo } from "react";

const Sidebar = () => {
    const actionContext = useContext(ActionContext);
    const authentication = useAuthentication();

    const handleClick = (action: ActionType | "removePassword" | "resetPassword") => (event: React.MouseEvent<HTMLElement>) => {
        event.currentTarget.blur();
        switch (action) {
            case "removePassword":
                authentication.removePassword();
                break;
            case "resetPassword":
                authentication.resetPassword();
                break;
            default:
                actionContext.updateAction(action);
                break;
        }
    };

    const authenticationActions = [
        { name: "Remove password", action: "removePassword" as ActionType | "removePassword" | "resetPassword" },
        { name: "Reset password", action: "resetPassword" as ActionType | "removePassword" | "resetPassword" },
    ];

    const actions = [
        { name: "Reset", action: ActionType.Reset },
        { name: "Unlock Connector", action: ActionType.UnlockConnector },
        { name: "Data Transfer", action: ActionType.DataTransfer },
        { name: "Remote Start Transaction", action: ActionType.RemoteStartTransaction },
        { name: "Remote Stop Transaction", action: ActionType.RemoteStopTransaction },
        { name: "Trigger Message", action: ActionType.TriggerMessage },
        { name: "Send Local List", action: ActionType.SendLocalList },
        { name: "Set Charging Profile", action: ActionType.SetChargingProfile },
        { name: "Clear Charging Profile", action: ActionType.ClearChargingProfile },
        { name: "Get Composite Schedule", action: ActionType.GetCompositeSchedule },
        { name: "Get Configuration", action: ActionType.GetConfiguration },
        { name: "Get Diagnostics", action: ActionType.GetDiagnostics },
        { name: "Change configuration", action: ActionType.ChangeConfiguration },
        { name: "Update firmware", action: ActionType.UpdateFirmware },
        { name: "Set Heartbeat Interval", action: ActionType.SetHeartbeatInterval },
        { name: "Disconnect", action: ActionType.Disconnect },
    ];

    return (
        <Box sx={{ height: "inherit", padding: "4rem", borderLeft: "1px solid", borderColor: "night.40" }}>
            <Section title="Authentication" icon="Lock" />
            <Stack direction="column" spacing="1.4rem" sx={{ marginTop: "3rem" }}>
                {authenticationActions.map(({ name, action }) => (
                    <Button key={name} variant="secondary" onClick={handleClick(action)}>
                        {name}
                    </Button>
                ))}
            </Stack>
            <Divider sx={{ marginTop: "3rem" }} />
            <Box sx={{ marginTop: "3rem" }} />
            <Section title="Commands" icon="Command" />
            <Stack direction="column" spacing="1.4rem" sx={{ marginTop: "3rem" }}>
                {actions.map(({ name, action }) => (
                    <Button key={name} variant="secondary" onClick={handleClick(action)}>
                        {name}
                    </Button>
                ))}
            </Stack>
        </Box>
    );
};

export default Sidebar;
