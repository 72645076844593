import { CacheProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import createEmotionCache from "app/components/common/createEmotionCache";
import AutoTestPage from "app/views/AutoTestPage";
import ChargePointPage from "app/views/ChargePointPage";
import IdentityPage from "app/views/IdentityPage";
import PageNotFoundPage from "app/views/PageNotFoundPage";

import StartPage from "app/views/StartPage";
import CpiThemeProvider from "lib/components/CpiThemeProvider";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import MontaAppLayout from "layout/MontaAppLayout";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export function App() {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                    },
                },
            })
    );

    return (
        <CacheProvider value={clientSideEmotionCache}>
            <CpiThemeProvider>
                <QueryClientProvider client={queryClient}>
                    <ConfirmProvider
                        defaultOptions={{
                            dialogProps: { sx: { ".MuiDialog-paper": { padding: "0" } } },
                        }}
                    >
                        <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                            <Routes>
                                <Route path="/" element={<MontaAppLayout />}>
                                    <Route index element={<StartPage />} />
                                    <Route path="charge-points/:identity" element={<ChargePointPage />} />
                                    <Route path="charge-points/:identity/auto" element={<AutoTestPage />} />
                                    <Route path="*" element={<PageNotFoundPage />} />
                                    <Route path="identity" element={<IdentityPage />} />
                                </Route>
                            </Routes>
                        </SnackbarProvider>
                    </ConfirmProvider>
                </QueryClientProvider>
            </CpiThemeProvider>
        </CacheProvider>
    );
}

export default App;
