import { createTheme } from "@mui/material/styles";

import * as colors from "./colors";

const Quicksand = '"Quicksand", sans-serif';

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        white: typeof colors.white;
        coral: typeof colors.coral;
        coralGradient: typeof colors.coralGradient;
        coralGradientFallbackDark: typeof colors.coralGradientFallbackDark;
        coralGradientFallbackDimmed: typeof colors.coralGradientFallbackDimmed;
        sand: typeof colors.sand;
        night: typeof colors.night;
        ocean: typeof colors.ocean;
        plum: typeof colors.plum;
        raspberry: typeof colors.raspberry;
        yellow: typeof colors.yellow;
        green: typeof colors.green;
        red: typeof colors.red;
    }

    interface PaletteOptions {
        white: typeof colors.white;
        coral: typeof colors.coral;
        coralGradient: typeof colors.coralGradient;
        coralGradientFallbackDark: typeof colors.coralGradientFallbackDark;
        coralGradientFallbackDimmed: typeof colors.coralGradientFallbackDimmed;
        sand: typeof colors.sand;
        night: typeof colors.night;
        ocean: typeof colors.ocean;
        plum: typeof colors.plum;
        raspberry: typeof colors.raspberry;
        yellow: typeof colors.yellow;
        green: typeof colors.green;
        red: typeof colors.red;
    }
}

export default createTheme({
    palette: {
        common: {
            white: colors.white,
            black: colors.black,
        },
        primary: colors.primary,
        secondary: colors.secondary,
        info: colors.info,
        error: colors.error,
        warning: colors.warning,
        success: colors.success,
        text: {
            primary: colors.night[100],
            secondary: colors.night[90],
            disabled: "rgba(122, 131, 137, 0.5)",
        },
        action: {
            hover: "rgba(122, 131, 137, 0.1)",
            selected: "rgba(122, 131, 137, 0.08)",
            disabled: "#B8BCBF",
            disabledBackground: "rgba(122, 131, 137, 0.5)",
        },
        white: colors.white,
        grey: colors.grey,
        coral: colors.coral,
        coralGradient: colors.coralGradient,
        coralGradientFallbackDark: colors.coralGradientFallbackDark,
        coralGradientFallbackDimmed: colors.coralGradientFallbackDimmed,
        sand: colors.sand,
        night: colors.night,
        ocean: colors.ocean,
        plum: colors.plum,
        raspberry: colors.raspberry,
        yellow: colors.yellow,
        red: colors.red,
        green: colors.green,
    },
    typography: {
        htmlFontSize: 10,
        fontFamily: Quicksand,
        fontWeightBold: 700,
        fontWeightMedium: 500,
        fontWeightRegular: 400,
        allVariants: {
            fontFamily: Quicksand,
            color: colors.night[90],
        },
        h1: {
            fontWeight: 700,
            fontSize: "4rem",
        },
        h2: {
            fontWeight: 700,
            fontSize: "3.5rem",
        },
        h3: {
            fontWeight: 700,
            fontSize: "3rem",
        },
        h4: {
            fontWeight: 700,
            fontSize: "2.5rem",
        },
        h5: {
            fontWeight: 700,
            fontSize: "2rem",
        },
        subtitle1: {
            fontWeight: 700,
            fontSize: "1.6rem",
        },
        subtitle2: {
            fontWeight: 700,
            fontSize: "1.5rem",
        },
        body1: {
            fontSize: "1.4rem",
        },
        body2: {
            fontSize: "1.4rem",
            fontWeight: 700,
        },
        button: {
            textTransform: "none",
            fontSize: "1.4rem",
            fontWeight: 700,
            lineHeight: "1.6rem",
        },
        caption: {
            fontWeight: 700,
            fontSize: "1.2rem",
        },
        overline: {
            fontSize: "1.2rem",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        html, body, #__next {
          overscroll-behavior: none;
        }

        div[scale="1"] {
          width: 100%;
        }

        html {
          font-size: 62.5%;
        }

        body {
          font-family: 'Quicksand', sans-serif;
          font-weight: 400;
          overflow-y: scroll !important;
          padding: 0 !important;
        }
      `,
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    ".MuiBackdrop-root": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    width: "100%",
                    height: "100%",
                    borderRadius: "0.8rem",
                    borderWidth: "0",
                    padding: "2.4rem",
                    boxSizing: "border-box",
                    position: "relative",
                    "&:before": {
                        content: '""',
                        position: "absolute",
                        left: "0",
                        right: "0",
                        top: "0",
                        bottom: "0",
                        zIndex: 0,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: colors.night[40],
                        pointerEvents: "none",
                        borderRadius: "0.8rem",
                    },
                },
            },
            defaultProps: {
                elevation: 0,
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(144, 150, 175, 0.3)",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    ".MuiDialog-paper": {
                        padding: "4rem",
                        borderRadius: "0.8rem",
                        boxShadow:
                            "0px 100px 80px rgba(52, 52, 87, 0.07), 0px 41.7776px 33.4221px rgba(52, 52, 87, 0.0503198), 0px 22.3363px 17.869px rgba(52, 52, 87, 0.0417275), 0px 12.5216px 10.0172px rgba(52, 52, 87, 0.035), 0px 6.6501px 5.32008px rgba(52, 52, 87, 0.0282725), 0px 2.76726px 2.21381px rgba(52, 52, 87, 0.0196802)",
                    },
                },
            },
        },
        MuiDialogTitle: {
            defaultProps: {
                typography: "h5",
            },
            styleOverrides: {
                root: {
                    backgroundColor: colors.night[10],
                    padding: "4rem 4rem 2rem",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: "3.2rem 4rem !important",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "3.2rem",
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
                action: {
                    alignSelf: "center",
                },
                avatar: {
                    marginRight: "2.4rem",
                },
            },
            defaultProps: {
                titleTypographyProps: {
                    variant: "subtitle1",
                },
                subheaderTypographyProps: {
                    variant: "body1",
                },
            },
        },
        MuiAvatar: {
            defaultProps: {
                variant: "circular",
            },
            styleOverrides: {
                root: {
                    backgroundColor: colors.ocean[20],
                    color: colors.ocean[80],
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variant: "body1",
                color: "textPrimary",
            },
        },
        MuiButton: {
            defaultProps: {
                color: "primary",
                variant: "contained",
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    borderRadius: "1rem",
                },
                sizeSmall: {
                    padding: "0.8rem 1.2rem",
                },
                sizeMedium: {
                    padding: "1.2rem 2.0rem",
                },
                sizeLarge: {
                    padding: "1.4rem 2.4rem",
                },
                startIcon: {
                    "*:nth-of-type(1)": {
                        width: "1.6rem",
                        height: "1.6rem",
                        fontSize: "inherit",
                    },
                },
                containedPrimary: {
                    background: colors.coralGradient,
                    color: colors.primary.contrastText,
                    "&:hover, &:active": {
                        background: colors.coralGradientFallbackDark,
                        color: colors.primary.contrastText,
                    },
                    "&.Mui-disabled": {
                        background: colors.coralGradientFallbackDimmed,
                        color: colors.primary.contrastText,
                    },
                },
                outlinedSecondary: {
                    borderWidth: 0,
                    position: "relative",
                    backgroundColor: colors.white,
                    color: colors.night[100],
                    boxShadow: "0px 0.2rem 0.6rem rgba(52, 52, 87, 0.06)",
                    "&:hover, &:active": {
                        borderWidth: 0,
                        backgroundColor: colors.white,
                        color: colors.coral[100],
                        boxShadow: "0px 0.2rem 0.6rem rgba(52, 52, 87, 0.06)",
                    },
                    "&.Mui-disabled": {
                        borderWidth: 0,
                        backgroundColor: colors.white,
                        color: colors.night[50],
                    },
                    "&:before": {
                        content: '""',
                        position: "absolute",
                        left: "0",
                        right: "0",
                        top: "0",
                        bottom: "0",
                        zIndex: 0,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: colors.night[40],
                        pointerEvents: "none",
                        borderRadius: "1rem",
                    },
                },
                textSecondary: {
                    backgroundColor: colors.white,
                    color: colors.night[100],
                    textDecoration: "underline",
                    "&:hover, &:active": {
                        backgroundColor: colors.white,
                        color: colors.night[90],
                    },
                    "&.Mui-disabled": {
                        color: colors.night[50],
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontWeight: "normal",
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: "primary",
            },
        },
        MuiRadio: {
            defaultProps: {
                color: "primary",
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: "primary",
            },
        },
    },
});
