import { AxiosError } from "axios";
import { useSnackbar } from "notistack";

function parseErrorMessage(error: any): string {
    let message = "Unknown Error";
    if (error instanceof AxiosError) {
        if (error.response) {
            message = error.response.data.message;
        }
    } else if (error instanceof Error) {
        message = error.message;
    }
    return message;
}

const useErrorHandler = () => {
    const { enqueueSnackbar } = useSnackbar();

    const onError = (error: unknown, prefix?: string) => {
        console.log(error);
        enqueueSnackbar(prefix + parseErrorMessage(error), { variant: "error" });
    };

    return {
        onError,
    };
};

export default useErrorHandler;
