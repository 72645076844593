import { ActionContext } from "app/components/action/ActionContext";
import { SetHeartbeatInterval } from "app/components/action/model/Actions";
import { ActionType } from "app/components/action/model/ActionType";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import useErrorHandler from "app/hooks/useErrorHandler";
import useRouter from "app/hooks/useRouter";
import OcppApiService from "app/services/OcppApiService";
import { Form, Formik, useFormikContext } from "formik";
import { Stack } from "lib/components";
import Button from "lib/components/Button/Button";
import { DialogContent, DialogFooter, DialogHeader } from "lib/components/Dialog";
import TextField from "lib/components/TextField/TextField";
import { useContext } from "react";
import * as yup from "yup";

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const { values, handleChange, isValid, errors } = useFormikContext<SetHeartbeatInterval>();

    return (
        <Form>
            <DialogHeader>Set Heartbeat Interval</DialogHeader>
            <DialogContent>
                <Stack direction="column" spacing="3rem">
                    <TextField
                        label="Heartbeat Interval (seconds)"
                        fullWidth
                        id="interval"
                        name="interval"
                        value={values.interval}
                        onChange={handleChange}
                        error={Boolean(errors.interval)}
                        helperText={errors.interval ? errors.interval : ""}
                    />
                </Stack>
            </DialogContent>
            <DialogFooter>
                <Button onClick={actionContext.onModalClose} variant="secondary">
                    Cancel
                </Button>
                <Button type="submit" variant="primary" disabled={!isValid}>
                    Submit
                </Button>
            </DialogFooter>
        </Form>
    );
};

const SetHeartbeatIntervalForm = () => {
    const { params } = useRouter();
    const actionContext = useContext(ActionContext);
    const { onError } = useErrorHandler();

    const handleSubmit = async (request: SetHeartbeatInterval) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.SetHeartbeatInterval, params.identity, request);
            await OcppApiService.setHeartbeatInterval(request);
            actionContext.onModalClose();
        } catch (error) {
            onError(error, "Command Failed: ");
        } finally {
        }
    };

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                interval: 180,
            }}
            validationSchema={yup.object({
                interval: yup.number().required().min(30, "Must be greater than 30 seconds"),
            })}
            onSubmit={handleSubmit}
        >
            <InnerForm />
        </Formik>
    );
};

export default SetHeartbeatIntervalForm;
