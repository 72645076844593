import { Config } from "app/Config";
import * as React from "react";
import { ChargePointLogEntry } from "./ChargePointLogEntry";

type LogContextValue = {
    logs: ChargePointLogEntry[];
    setLogs: React.Dispatch<React.SetStateAction<ChargePointLogEntry[]>>;
    parseJsonContext: (log: ChargePointLogEntry) => any;
    findRelated: (log: ChargePointLogEntry) => ChargePointLogEntry[];
};

const LogsContext = React.createContext<LogContextValue | undefined>(undefined);
const LOG_MAX_LENGTH = 2000;

function LogsProvider({ children, identity }: React.PropsWithChildren<{ identity: string }>) {
    const [chargePointLogs, setChargePointLogs] = React.useState<ChargePointLogEntry[]>([]);
    const logIds = React.useRef<Set<string>>(new Set());

    const wsRef = React.useRef<WebSocket | null>(null);
    const wsShutdown = React.useRef<boolean>(false);

    const parsedJsonContext = React.useRef(new WeakMap<ChargePointLogEntry, any>());

    const parseJsonContext = (log: ChargePointLogEntry): any => {
        const parsedJsonContextMap = parsedJsonContext.current;
        if (parsedJsonContextMap.has(log)) {
            return parsedJsonContextMap.get(log);
        }
        if (!log.context) {
            return null;
        }
        const data = JSON.parse(log.context.json);
        parsedJsonContextMap.set(log, data);
        return data;
    };

    const findRelated = (log: ChargePointLogEntry): ChargePointLogEntry[] => {
        function isOcppArray(data: any): data is [number, string] {
            return data != null && typeof data[0] === "number" && typeof data[1] === "string";
        }
        const jsonData = parseJsonContext(log);
        if (!isOcppArray(jsonData)) {
            return [];
        }
        return chargePointLogs.filter((otherLog) => {
            const otherJsonData = parseJsonContext(otherLog);
            if (!isOcppArray(otherJsonData)) {
                return false;
            }
            return otherJsonData[1] === jsonData[1] && otherLog !== log;
        });
    };

    const connect = (identity: string) => {
        const connectAttempt = () => {
            if (wsRef.current) {
                return;
            }

            const websocket = new WebSocket(Config.baseWssUrl + "/api/charge-point/" + identity + "/logs");

            wsRef.current = websocket;

            // Function to send heartbeat message
            const sendHeartbeat = () => {
                if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                    wsRef.current.send(JSON.stringify({ type: "heartbeat" }));
                }
            };

            // Send heartbeat message every X milliseconds
            const heartbeatInterval = setInterval(sendHeartbeat, 10000);

            websocket.onmessage = (event: MessageEvent) => {
                const data = JSON.parse(event.data) as ChargePointLogEntry;
                const logId = data.timestamp + (parseJsonContext(data) ? parseJsonContext(data)[1] : "");
                if (!logIds.current.has(logId)) {
                    logIds.current.add(logId);
                    setChargePointLogs((chargePointLogs) => [data, ...chargePointLogs].slice(0, LOG_MAX_LENGTH));
                }
            };

            websocket.onerror = (error) => {
                console.error("websocket error", error);
            };

            websocket.onclose = () => {
                console.log("websocket disconnected");
                wsRef.current = null;
                clearInterval(heartbeatInterval); // Clear the heartbeat interval on close
                if (!wsShutdown.current) {
                    setTimeout(connectAttempt, 5000);
                }
            };
        };
        connectAttempt();
    };

    React.useEffect(() => {
        connect(identity);
        return () => {
            if (wsRef.current) {
                if (wsRef.current?.readyState === 1) {
                    wsShutdown.current = true;
                    wsRef.current.close();
                    wsShutdown.current = false;
                }
            }
            setChargePointLogs([]);
            logIds.current.clear();
        };
    }, [identity]);

    const value: LogContextValue = {
        logs: chargePointLogs,
        setLogs: setChargePointLogs,
        parseJsonContext: parseJsonContext,
        findRelated: findRelated,
    };

    return <LogsContext.Provider value={value}>{children}</LogsContext.Provider>;
}

function useLogsContext(): LogContextValue {
    const context = React.useContext(LogsContext);
    if (context === undefined) {
        throw new Error("useLogsContext must be used within a LogsProvider");
    }
    return context;
}

function useLogs(): LogContextValue["logs"] {
    const logsContext = useLogsContext();
    return logsContext.logs;
}

function useLogParser(): LogContextValue["parseJsonContext"] {
    const logsContext = useLogsContext();
    return logsContext.parseJsonContext;
}

export { LogsProvider, useLogs, useLogParser };
