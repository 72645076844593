import MuiAvatar from "@mui/material/Avatar";
import getBackgroundColor from "../../styles/getBackgroundColor";

import getColor from "../../styles/getColor";

import Box from "../Box";

import { AvatarProps } from "./types";

const getPadding = (size: AvatarProps["size"]) => {
    switch (size) {
        case "large":
            return "2rem";
        case "medium":
            return "1.4rem";
        case "small":
        default:
            return "0.5rem";
    }
};

const getSize = (size: AvatarProps["size"]) => {
    switch (size) {
        case "large":
            return "8rem";
        case "medium":
            return "4.8rem";
        case "small":
        default:
            return "3rem";
    }
};

const Avatar = ({ children, color = "ocean", size = "large", isInverted }: AvatarProps) => {
    return (
        <MuiAvatar
            sx={{
                width: getSize(size),
                height: getSize(size),
                color: getColor(color, isInverted),
                backgroundColor: getBackgroundColor(color, isInverted),
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: getPadding(size),
                    width: "100%",
                    height: "100%",
                }}
            >
                {children}
            </Box>
        </MuiAvatar>
    );
};

export default Avatar;
