export default interface ChargePointTestStepDTO {
    name: string;
    testId: string;
    result: TestResult;
    startedAt: Date;
}

export enum TestResult {
    Success = "Success",
    Failure = "Failure",
    Pending = "Pending",
}
