import Dialog, { DialogContent, DialogFooter, DialogHeader } from "lib/components/Dialog";
import { Typography } from "lib/components";
import { useEffect, useRef, useState } from "react";
import { Config } from "app/Config";
import { AuthorizationPayload } from "app/components/authorization/AuthorizationPayload";
import OcppApiService from "app/services/OcppApiService";
import { LoadingButton } from "@mui/lab";
import useErrorHandler from "app/hooks/useErrorHandler";

interface AuthorizationModalProps {
    identity: string;
}

const AuthorizationModal = (props: AuthorizationModalProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [authorization, setAuthorization] = useState<AuthorizationPayload | undefined>(undefined);
    const { onError } = useErrorHandler();
    const wsRef = useRef<WebSocket>(null);

    const connect = (identity: string) => {
        if (wsRef.current) {
            return;
        }

        const websocket = new WebSocket(Config.baseWssUrl + "/api/charge-point/" + identity + "/auth");

        wsRef.current = websocket;

        websocket.onmessage = (event: MessageEvent) => {
            const payload = JSON.parse(event.data) as AuthorizationPayload;
            setAuthorization(payload);
        };

        websocket.onclose = () => {
            console.log("websocket disconnected");
            wsRef.current = null;
            connect(identity);
        };
    };

    useEffect(() => {
        connect(props.identity);
        return () => {
            if (wsRef.current) {
                if (wsRef.current?.readyState === 1) {
                    wsRef.current.close();
                }
            }
        };
    }, [props.identity]);

    const approveAuthorization = async () => {
        if (!authorization) return;
        try {
            setLoading(true);
            await OcppApiService.authorize(props.identity, authorization);
        } catch (e) {
            onError(e);
        } finally {
            setAuthorization(undefined);
            setLoading(false);
        }
    };

    const clearAuthorization = () => {
        setAuthorization(undefined);
    };

    return (
        <Dialog isOpen={authorization != undefined} onClose={clearAuthorization}>
            <DialogHeader>Authorization</DialogHeader>
            <DialogContent>
                <Typography>Do you want to authorize the following idTag {authorization?.id_tag}</Typography>
            </DialogContent>
            <DialogFooter>
                <LoadingButton loading={loading} onClick={clearAuthorization}>
                    Decline
                </LoadingButton>
                <LoadingButton loading={loading} onClick={approveAuthorization}>
                    Accept
                </LoadingButton>
            </DialogFooter>
        </Dialog>
    );
};

export default AuthorizationModal;
