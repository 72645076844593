import React from "react";

import Theme from "../../styles/theme";
import { CssBaseline, ThemeProvider } from "../index";

import { CpiThemeProviderProps } from "./types";

const CpiThemeProvider = ({ children }: CpiThemeProviderProps): React.JSX.Element => {
    return (
        <ThemeProvider theme={Theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default CpiThemeProvider;
