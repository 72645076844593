import { ActionContext } from "app/components/action/ActionContext";
import { Disconnect } from "app/components/action/model/Actions";
import { ActionType } from "app/components/action/model/ActionType";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import useErrorHandler from "app/hooks/useErrorHandler";
import useRouter from "app/hooks/useRouter";
import OcppApiService from "app/services/OcppApiService";
import { Form, Formik } from "formik";
import { Checkbox, FormControlLabel } from "lib/components";
import Button from "lib/components/Button/Button";
import { DialogContent, DialogFooter, DialogHeader } from "lib/components/Dialog";
import { useContext, useState } from "react";
import * as yup from "yup";

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleChange = () => {
        setIsConfirmed(!isConfirmed);
    };

    return (
        <Form>
            <DialogHeader>Disconnect</DialogHeader>
            <DialogContent>
                <FormControlLabel control={<Checkbox checked={isConfirmed} onChange={handleChange} />} label="Yes, I want to disconnect" />
            </DialogContent>
            <DialogFooter>
                <Button onClick={actionContext.onModalClose} variant="secondary">
                    Cancel
                </Button>
                <Button type="submit" variant="primary" disabled={!isConfirmed}>
                    Submit
                </Button>
            </DialogFooter>
        </Form>
    );
};

const DisconnectForm = () => {
    const { params } = useRouter();
    const actionContext = useContext(ActionContext);
    const { onError } = useErrorHandler();

    const handleSubmit = async (request: Disconnect) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.Disconnect, params.identity, request);
            await OcppApiService.disconnect(request);
            actionContext.onModalClose();
        } catch (error) {
            onError(error, "Command Failed: ");
        } finally {
        }
    };

    return (
        <Formik
            initialValues={{
                identity: params.identity,
            }}
            validationSchema={yup.object({
                identity: yup.string().required("Identity is a required field"),
            })}
            onSubmit={handleSubmit}
        >
            <InnerForm />
        </Formik>
    );
};

export default DisconnectForm;
